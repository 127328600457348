import { CurrencyType, ModelVariableUnit } from "../models";

const countDecimals = (value: number) => {
    if (Math.abs(Math.floor(value) - value) > 0) {
        let splitArray = value.toString().split(".");
        return splitArray.length > 1 ? splitArray[1].length : 0;
    } else {
        return 0;
    }
};

const getCurrencySymbol = (currency: CurrencyType) => {
    switch (currency) {
        case CurrencyType.USD:
            return "$";
        case CurrencyType.INR:
            return "₹";
        default:
            return "$";
    }
};

enum LocaleType {
    enUS = "en-US",
    enIN = "en-IN",
}

const getLocale = (currency: CurrencyType) => {
    switch (currency) {
        case CurrencyType.USD:
            return LocaleType.enUS;
        case CurrencyType.INR:
            return LocaleType.enIN;
        default:
            return LocaleType.enUS;
    }
};

const formatCurrencyByType = (
    currency: CurrencyType,
    value: number,
    decimalPlaces: number,
) => {
    const currencyFormat = new Intl.NumberFormat(getLocale(currency), {
        style: "currency",
        currency: currency,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });
    return currencyFormat.format(value);
};

const formatNumberByLocale = (
    locale: LocaleType,
    value: number,
    decimalPlaces: number,
) => {
    const numberFormat = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });
    return numberFormat.format(value);
};

const currencyFormat = (
    currency: CurrencyType,
    num: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => {
    if ("function" === typeof abbrFunc) {
        const symbol = getCurrencySymbol(currency);
        return `${symbol}${abbrFunc(num, isInteger ? 0 : 2)}`;
    }
    return formatCurrencyByType(currency, num, isInteger ? 0 : 2);
    // symbol +
    // Number(num)
    //     .toFixed(isInteger ? 0 : 2)
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
};

const percentFormat = (
    num: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => {
    if ("function" === typeof abbrFunc) {
        return `${abbrFunc(num, isInteger ? 0 : 2)}%`;
    }
    return Number(num).toFixed(isInteger ? 0 : 2) + "%";
};

const noneFormat = (
    num: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => {
    if ("function" === typeof abbrFunc) {
        return `${abbrFunc(num, isInteger ? 0 : 2)}`;
    }
    let decimalCount = isInteger ? 0 : Math.min(2, countDecimals(num));
    return Number(num).toFixed(decimalCount);
};

const nullFormat = (
    locale: LocaleType,
    num: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => {
    if ("function" === typeof abbrFunc) {
        return `${abbrFunc(num, isInteger ? 0 : 2)}`;
    }
    let decimalCount = isInteger ? 0 : Math.min(2, countDecimals(num));
    return formatNumberByLocale(locale, num, decimalCount);
    // Number(num)
    //     .toFixed(decimalCount)
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const variableValueFormatter = (
    currency: CurrencyType,
    unitType: ModelVariableUnit,
    value: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
): string => {
    let formattedEntry = "";
    switch (unitType) {
        case ModelVariableUnit.Dollars:
            formattedEntry = currencyFormat(
                currency,
                value,
                isInteger,
                abbrFunc,
            );
            break;
        case ModelVariableUnit.Percent:
            formattedEntry = percentFormat(value, isInteger, abbrFunc);
            break;
        case ModelVariableUnit.None:
            formattedEntry = noneFormat(value, isInteger, abbrFunc);
            break;
        default:
            formattedEntry = nullFormat(
                getLocale(currency),
                value,
                isInteger,
                abbrFunc,
            );
    }
    return formattedEntry;
};
