import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { ThemeObject } from "../ThemeObjects";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { StyledUniversalDiv } from "./Universal";
import { ChevronDoubleDownIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useGetElementAsync } from "@/hooks/useGetElementAsync";

type StyledTopDrawerProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    children?: ReactNode;
    contentBlockId?: string;
    isSelected: boolean;
};

const StyledTopDrawer: React.FC<StyledTopDrawerProps> = ({
    theme,
    children,
    contentBlockId,
    onClick,
    onHover,
    isInEditorContext,
    isSelected,
    title,
}) => {
    const [isOpen, setIsOpen] = useState(isSelected);

    const topBarIsOpen = useMemo(() => {
        return isOpen || isSelected;
    }, [isOpen, isSelected]);

    const bottomClass = useMemo(() => {
        return !topBarIsOpen ? "-translate-y-[100%]" : "translate-y-0";
    }, [topBarIsOpen, isInEditorContext]);

    return (
        <StyledUniversalDiv
            {...theme}
            data-content-block-id={contentBlockId}
            data-content-block-type={title || "TopDrawer"}
            data-admin={!!isInEditorContext}
            onClick={(evt) => {}}
            onMouseMove={(evt) => {}}
            className={`top-bar border-shadow-2xl absolute right-0 top-0 z-[99999] flex w-full flex-col
            items-center justify-center space-y-1 border border-t border-gray-200 bg-white
            p-2 shadow-lg transition-all duration-700 ease-in-out data-[admin=false]:fixed
            lg:flex-row lg:space-y-0 lg:py-1 ${bottomClass} @4xl:pl-12 @4xl:pr-4`}
        >
            {children}
            <div
                className="absolute -bottom-8 flex flex-col items-center justify-center rounded-b-md
                    bg-white p-2 shadow-lg"
            >
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        setIsOpen(!isOpen);
                    }}
                >
                    {topBarIsOpen ? (
                        <XMarkIcon className="h-4 w-4" />
                    ) : (
                        <ChevronDoubleDownIcon className="h-4 w-4" />
                    )}
                </button>
            </div>
        </StyledUniversalDiv>
    );
};
export const defaultTopDrawerTheme: Partial<ThemeObject> = {
    color: "#000000",
    backgroundColor: "#ffffffff",
};
export { StyledTopDrawer };
