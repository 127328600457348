import React from "react";
import { ContentBlockShape } from "@/models";
import { cn } from "@/util";
import "./flip-cards.css";
import FlipCardSide from "./FlipCardSide";
type Props = {
    contentBlock: ContentBlockShape;
    sides: ContentBlockShape[];
    isInAdminContext: boolean;
};

export default function FlipCard({
    contentBlock,
    sides,
    isInAdminContext,
}: Props) {
    const [isFlipped, setIsFlipped] = React.useState(false);

    return (
        <>
            <div
                className="h-96 w-64 perspective-750 has-[.tippy-box]:z-[99999]
                    has-[.tippy-box]:overflow-visible"
                data-content-block-id={contentBlock.id}
            >
                <div
                    className={`relative h-full w-full origin-right rounded-lg bg-white
                    shadow-[0_5px_10px_rgba(0,0,0,0.15)] transition-all duration-500 ease-in-out
                    transform-style-3d data-[side='back']:-translate-x-full
                    data-[side='back']:-rotate-y-180 hover:shadow-[0_5px_15px_rgba(0,0,0,0.25)]
                    ${cn(contentBlock?.theme.tailwindClasses)}`}
                    data-side={isFlipped ? "back" : "front"}
                    data-is-in-admin-context={isInAdminContext}
                >
                    {sides.map((side) => (
                        <FlipCardSide
                            key={side.id}
                            contentBlock={side}
                            isInAdminContext={isInAdminContext}
                            flip={() => setIsFlipped(!isFlipped)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
}
