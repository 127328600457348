import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { roundStoreReducer as roundStore } from "./roundDataSlice";
import { timehorizonStoreReducer as timeHorizonStore } from "./timeHorizonSlice";
import { contentBlockStoreReducer as contentBlockStore } from "./contentBlockDataSlice";
import { contentEditorStoreReducer as contentEditorStore } from "./contentEditorDataSlice";
import { simulationStoreReducer as simulationStore } from "./simulationSlice";
import { cohortStoreReducer as cohortStore } from "./cohortSlice";
import { uploadFileReducer as uploadStore } from "./uploadFileSlice";
import { colorStoreReducer as colorStore } from "./colorSlice";
import { contentSelectorStoreReducer as contentSelectorStore } from "./contentSelectorSlice";
import * as modelSchema from "./schema";
import { stores } from "../stores";
import { contentBlockStoreReducer as participantContentBlockStore } from "../Pages/Participant/SimulationDisplay/redux-state/contentBlockSlice";
import { simulationThemeStoreReducer as simulationThemeStore } from "../Pages/Participant/SimulationDisplay/redux-state/simulationThemeSlice";
import { othersSelectionsReducer as othersSelectionsStore } from "../Pages/Participant/SimulationDisplay/redux-state/othersSelectionsSlice";
// import { colorStoreReducer as colorStore } from "../../../from "@/redux-state"/colorSlice";
import { promptSliceReducer as promptStore } from "../Pages/Participant/SimulationDisplay/redux-state/promptSlice";
import { notificationStoreReducer as notificationStore } from "./notificationSlice";
import { modelBuilderReducers as modelBuilderStore } from "./modelBuilder";
import { modalComponentStoreReducer as modalComponentStore } from "./modalComponentSlice";
import { displayRuleSliceReducer as displayRuleStore } from "./displayRuleSlice";
import { variableValueSliceReducer as variableValueStore } from "./variableValueSlice";
import { simVariantStoreReducer as simVariantState } from "./simVariantSlice";
import { mobileViewReducer as mobileViewState } from "../Pages/Admin/editor/mobile-wrapper/mobileViewSlice";
import { sidebarStateReducer as sidebarState } from "./sidebarStateSlice";
import { reflectionJournalEntryReducer as reflectionJournalEntryStore } from "../Pages/Participant/SimulationDisplay/redux-state/reflectionJournalEntrySlice";
import { submissionCountReducer as submissionCountStore } from "../Pages/Participant/SimulationDisplay/redux-state/submissionCountSlice";

const reducersToCombine = {
    timeHorizonStore,
    roundStore,
    contentBlockStore,
    contentEditorStore,
    modelBuilderStore,
    uploadStore,
    simulationStore,
    cohortStore,
    colorStore,
    contentSelectorStore,
    participantContentBlockStore,
    simulationThemeStore,
    promptStore,
    notificationStore,
    modalComponentStore,
    displayRuleStore,
    variableValueStore,
    simVariantState,
    othersSelectionsStore,
    mobileViewState,
    sidebarState,
    reflectionJournalEntryStore,
    submissionCountStore,
} as const;

export const rootReducer = combineReducers(
    Object.keys(reducersToCombine).reduce((carry, storeKey) => {
        return { ...carry, [storeKey]: reducersToCombine[storeKey] };
    }, {}) as typeof reducersToCombine
);


const middleware = getDefaultMiddleware({
    thunk: {
        extraArgument: {
            schema: modelSchema,
            api: stores,
        },
    },
    serializableCheck: false,
});
const AppStore = configureStore({
    reducer: rootReducer,
    middleware,
});
export type AppDispatch = typeof AppStore.dispatch;
const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
type RootState = ReturnType<typeof rootReducer>;

export interface extraThunkArguments {
    schema: modelSchema.modelSchemaShape;
    api: typeof stores;
}

export {
    AppStore,
    useAppDispatch,
    // rootReducer,
    useAppSelector,
    // reducersToCombine,
};
