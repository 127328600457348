import { BaseModel, IBaseModel } from "./BaseModel";
import { Round } from "./Round";
import { Cohort } from "./Cohort";
import { ModelBlock } from "./ModelBlock";
import { CurrencyType, ModelVariableScope } from "./ModelVariable";
import { User } from ".";
import { TimeHorizon } from "./TimeHorizon";
import { ColorGroup } from "./ColorGroup";
import { SubmoduleTree } from "./SubmoduleTree";

type SimulationConfigurationOptions = {
    allowSelfAdvance?: boolean;
    defaultPromptScope?: "Participant" | "Team";
    defaultModelVariableScope?: ModelVariableScope;
    currency?: CurrencyType;
};

export const defaultSimConfig: SimulationConfigurationOptions = {
    allowSelfAdvance: false,
    defaultPromptScope: "Participant",
    defaultModelVariableScope: ModelVariableScope.Team,
    currency: CurrencyType.USD,
};

export type SimulationShape = IBaseModel & {
    title: string;
    slug: string;
    config?: SimulationConfigurationOptions;
    rounds: Round[];
    description?: string;
    theme?: any;
    variants_count?: number;
    rounds_count?: number;
    weight: number;
    cohorts?: Cohort[];
    modelBlocks?: ModelBlock[];
    createdBy?: User;
    created_by_id?: string;
    timeHorizons?: TimeHorizon[];
    colorGroups?: ColorGroup[];
    estimated_time?: number;
    interactiveBlocks?: ModelBlock[];
    submoduleBlocks?: ModelBlock[];
    submoduleTrees?: SubmoduleTree[];
    model_blocks_count?: number;
};

export class Simulation extends BaseModel implements SimulationShape {
    constructor(data = null) {
        super();
        if (data) {
            this.setUp(data);
        }
    }

    setUp(data) {
        if (data) {
            for (let prop in data) {
                this[prop] = data[prop];
            }
        }
    }

    public static CLASSNAME: string = "Simulation";
    public static END_POINT: string = "/api/simulations/";

    title: string = "";
    slug: string = "";
    config?: SimulationConfigurationOptions = defaultSimConfig;
    rounds: Round[] = [];
    description?: string;
    theme?: any;
    variants_count?: number;
    rounds_count?: number;
    weight: number = 0;
    cohorts?: Cohort[];
    modelBlocks?: ModelBlock[];
    createdBy?: User;
    timeHorizons?: TimeHorizon[];
    colorGroups?: ColorGroup[];
    estimated_time?: number;
    interactiveBlocks?: ModelBlock[];
    submoduleBlocks?: ModelBlock[];
    submoduleTrees?: SubmoduleTree[];
}
