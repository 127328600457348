import { useCallback, useEffect, useState } from "react";
import {
    BaseModel,
    ContentBlockShape,
    Option,
    OptionShape,
    Prompt,
    PromptShape,
    PromptType,
} from "@/models";
import { mapById, useAppDispatch } from "@/redux-state";
import { SapienInertia } from "@/inertia-utils/hooks";
import { LaravelRequestBodyShapes } from "@/ziggy-shims";
import { useModalQueryParams } from "./useModalQueryParams/useModalQueryParams";

export const getSortedIdArrayAndMapById = <T extends BaseModel>(
    modelArray: T[],
): {
    sortedIdArray: string[];
    mapById: { [index: string]: T };
} => {
    if (modelArray !== undefined) {
        let sortedModels = [...modelArray].sort((a, b) => a.weight - b.weight);
        let newSortedIdArray = sortedModels.map((model) => model.id);
        let newMapById = mapById(sortedModels);
        return {
            sortedIdArray: newSortedIdArray,
            mapById: newMapById,
        };
    } else {
        return { sortedIdArray: [], mapById: {} };
    }
};

export interface QuestionConfig {
    hasHiddenOption?: boolean;
    exactOptionCount?: number;
    requiresNumericalData?: boolean;
    allowsNumericalData?: boolean;
    canSpecifyOptionCountOnCreation?: boolean;
    requiresManualOptionCreation?: boolean;
}

export const QuestionConfigObject: {
    [index in PromptType]: QuestionConfig;
} = {
    [PromptType["Multiple Choice"]]: {
        canSpecifyOptionCountOnCreation: true,
    },
    [PromptType["Multiple Select"]]: {
        canSpecifyOptionCountOnCreation: true,
    },
    [PromptType["Numerical Input"]]: {
        hasHiddenOption: true,
        allowsNumericalData: true,
    },
    [PromptType["Numerical Slider"]]: {
        hasHiddenOption: true,
        requiresNumericalData: true,
    },
    [PromptType["Rank Order"]]: {
        canSpecifyOptionCountOnCreation: true,
    },
    [PromptType["Toggle Switch"]]: {
        exactOptionCount: 2,
    },
    [PromptType["Long Text"]]: {
        hasHiddenOption: true,
    },
    [PromptType["Short Text"]]: {
        hasHiddenOption: true,
    },
    [PromptType["Dropdown List"]]: {
        canSpecifyOptionCountOnCreation: true,
    },
    [PromptType["Drag and Drop"]]: {
        requiresManualOptionCreation: true,
    },
    [PromptType.Timeline]: {
        requiresManualOptionCreation: true,
    },
};

export const useQuestionEditor = (
    contentBlockId: string,
    initialPrompt: PromptShape = {
        prompt_type: PromptType["Multiple Choice"],
        content_block_id: contentBlockId,
        content: "",
        is_required: false,
        min: 0,
        max: 0,
        increment: 0,
        options: [],
        weight: 0,
        scope: "Participant",
        selectionContainer: null,
        optionContainers: [],
        selectionContainers: [],
    },
    promptType: PromptType = PromptType["Multiple Choice"],
) => {
    const { closeModal } = useModalQueryParams();
    const dispatch = useAppDispatch();

    const [prompt, setPrompt] = useState<PromptShape>(
        initialPrompt?.id
            ? initialPrompt
            : {
                  ...initialPrompt,
                  prompt_type: promptType,
              },
    );
    if (promptType && !initialPrompt.id) {
    }
    useEffect(() => {
        if (initialPrompt?.id) {
            setPrompt(initialPrompt);
        }
    }, [initialPrompt]);

    const [afterSaveRoute, setAfterSaveRoute] = useState("");
    const [questionConfig, setQuestionConfig] = useState<QuestionConfig>();
    const [sortedOptionIds, setSortedOptionIds] = useState<string[]>();
    const [optionMap, setOptionMap] = useState<{
        [index: string]: OptionShape;
    }>();
    const [deletedOptionIds, setDeletedOptionIds] = useState([]);
    const markOptionDeletionState = useCallback(
        (option: Option | OptionShape) => {
            if (deletedOptionIds.includes(option.id)) {
                setDeletedOptionIds((deletedOptionIds) =>
                    deletedOptionIds.filter((id) => id !== option.id),
                );
            } else {
                setDeletedOptionIds((deletedOptionIds) => [
                    ...deletedOptionIds,
                    option.id,
                ]);
            }
        },
        [deletedOptionIds],
    );

    // content block and id --> prompt, sortedOptionIds, optionMap
    useEffect(() => {
        if (
            contentBlockId &&
            !!prompt &&
            (sortedOptionIds === undefined || sortedOptionIds.length === 0)
        ) {
            let sortedOptionIdsAndOptionMap =
                getSortedIdArrayAndMapById<OptionShape>(
                    prompt.options !== undefined ? prompt.options : [],
                );
            setSortedOptionIds(sortedOptionIdsAndOptionMap.sortedIdArray);
            setOptionMap(sortedOptionIdsAndOptionMap.mapById);
        }
    }, [contentBlockId, prompt]);

    // prompt --> questionConfig, isNewQuestion
    useEffect(() => {
        if (prompt !== undefined) {
            setQuestionConfig(QuestionConfigObject[prompt.prompt_type]);
        }
    }, [prompt]);

    const preparePromptForSave = useCallback(() => {
        let promptToSave = prompt;

        let options: {
            content: string;
            weight: number;
            size?: number;
            drag_and_drop_prompt_container_id?: string;
        }[] = [];
        if (
            (!promptToSave.id || !promptToSave?.options?.length) &&
            QuestionConfigObject[promptToSave.prompt_type].hasHiddenOption
        ) {
            options = [
                {
                    content: "option",
                    weight: 0,
                },
            ];
        } else {
            options = sortedOptionIds
                .filter((id) => !deletedOptionIds.includes(id))
                .map((id, i) => ({
                    ...optionMap[id],
                    weight: i,
                }))
                .sort((a, b) => a.weight - b.weight)
                .map((option, weight) => {
                    const optionToSave = {
                        ...option,
                        id:
                            option.id == option.weight.toString() ||
                            !isNaN(Number(option.id))
                                ? ""
                                : option.id,
                        weight,
                    };
                    if (!optionToSave.id) delete optionToSave.id;

                    return optionToSave;
                }) as {
                content: string;
                weight: number;
            }[];
        }

        return {
            ...promptToSave,
            options,
        } as Prompt;
    }, [
        prompt,
        optionMap,
        questionConfig,
        dispatch,
        sortedOptionIds,
        deletedOptionIds,
    ]);

    const handleSavePrompt = useCallback(
        (prompt: Prompt) => {
            if (!prompt.id) {
                SapienInertia.post(
                    "creator.design.questions.store",
                    {
                        ...prompt,
                    } as any as (typeof LaravelRequestBodyShapes)["creator.design.questions.store"],
                    {},
                    {
                        onSuccess() {
                            closeModal();
                        },
                        preserveScroll: true,
                    },
                );
            } else {
                SapienInertia.put(
                    "creator.design.questions.update",
                    {
                        ...prompt,
                        deletedOptionIds,
                    } as any as (typeof LaravelRequestBodyShapes)["creator.design.questions.update"],
                    {
                        question: prompt.id,
                    },
                    {
                        onSuccess() {
                            closeModal();
                        },
                    },
                );
            }
        },
        [
            prompt,
            optionMap,
            questionConfig,
            dispatch,
            sortedOptionIds,
            afterSaveRoute,
            deletedOptionIds,
        ],
    );

    const setOption = (option: Option | OptionShape) => {
        setOptionMap((optionMap) => ({
            ...optionMap,
            ...{
                [option.id]: option,
            },
        }));
    };

    const addOption = useCallback(
        (
            drag_and_drop_prompt_container_id: string | null = null,
            contentBlockFunction: (
                content: string,
                parent_content_block_id: string,
                weight: number,
            ) => ContentBlockShape | null = null,
        ) => {
            let optionArray = Object.values(optionMap);
            let maxWeight = Math.max(
                ...optionArray.map((option) => option.weight),
                0,
            );

            let newOption = new Option({
                id: (maxWeight + 1).toString(),
                weight: maxWeight + 1,
                content: `option ${optionArray.length + 1}`,
                drag_and_drop_prompt_container_id,
            });

            if ("function" === typeof contentBlockFunction) {
                newOption.contentBlock = contentBlockFunction(
                    newOption.content,
                    prompt.content_block_id,
                    newOption.weight,
                );

                console.log(newOption.contentBlock);
            }

            let sortedOptionIdsAndOptionMap =
                getSortedIdArrayAndMapById<OptionShape>([
                    ...optionArray,
                    newOption,
                ]);
            setSortedOptionIds(sortedOptionIdsAndOptionMap.sortedIdArray);
            setOptionMap(sortedOptionIdsAndOptionMap.mapById);
        },
        [optionMap, sortedOptionIds, prompt],
    );

    const onSelectPromptFromBank = useCallback(
        (promptFromQuestionBank: Prompt) => {
            const { min, max, content, increment, scope, is_required } =
                promptFromQuestionBank;

            //update state of prompt to version of passed promts w/o ids on prompt or options
            const updatedPrompt = {
                ...prompt,
                ...{
                    min,
                    max,
                    content,
                    increment,
                    scope,
                    is_required,
                },
            };

            setPrompt(updatedPrompt as Prompt);

            const newOptions = promptFromQuestionBank.options.map((option) => {
                const { weight, content } = option;
                return new Option({
                    weight,
                    content,
                    id: weight.toString(),
                });
            });
            let sortedOptionIdsAndOptionMap =
                getSortedIdArrayAndMapById<Option>(newOptions);
            setSortedOptionIds(sortedOptionIdsAndOptionMap.sortedIdArray);
            setOptionMap(sortedOptionIdsAndOptionMap.mapById);
        },
        [prompt],
    );

    return {
        prompt,
        setPrompt,
        questionConfig,
        sortedOptionIds,
        setSortedOptionIds,
        optionMap,
        savePrompt: handleSavePrompt,
        preparePromptForSave,
        setOption,
        addOption,
        onSelectPromptFromBank,
        afterSaveRoute,
        setAfterSaveRoute,
        deletedOptionIds,
        markOptionDeletionState,
    };
};
